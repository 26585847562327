<template>
  <div>
    <div v-if="!showingPreview" style="display: flex" class="full-width-padding">
      <p class="text-hoverable" @click="$router.go(-1)" style="display: flex; justify-content: flex-start; align-items: center">
        <BackIcon style="margin-right: 10px"/>Tillbaka
      </p>
    </div>
    <div v-if="!showingPreview" class="admin-write">
      <div style="display: flex; justify-content: end">
        <p class="auth text-hoverable" :style="{color: !english ? '#269400' : 'black', fontWeight: !english ? 'bold' : 'normal'}" style="margin-right: 10px" @click="english = false">Svenska</p>
        <p class="auth text-hoverable" :style="{color: english ? '#269400' : 'black', fontWeight: english ? 'bold' : 'inherit'}" @click="english = true">Engelska</p>
      </div>
      <h3 style="text-align: left">Titel</h3>
      <input class="auth" type="text" style="margin-bottom: 50px" v-model="title[+english]" @change="slug">
      <h3 style="text-align: left">Innehåll</h3>
      <quillEditor v-model="content[+english]"
                  :options="editorOptions"
                  style="margin-bottom: 50px" 
      ></quillEditor>
      <h3 style="text-align: left">Bilder</h3>
      
      <div class="admin-write-images" style="margin-bottom: 50px;">
        <button class="auth" style="position: relative; margin-bottom: 20px" @click="$refs.input.click()">
          <div v-if="imageLoading" class="auth-loader" style="position: absolute; left: 15px; top: 12px"></div>
          Välj bild
        </button>
        <input type="file" ref="input" style="display: none;" @change="updateImages">
        <div class="admin-write-image-preview-list">
          <div v-for="url in images" :key="url" style="display: flex; flex-direction: column; align-items: center">
            <img :src="url" class="admin-write-image-preview">
            <p class="auth text-hoverable" style="font-size: 16px" @click="removeImage(url)">Ta bort</p>
          </div>
        </div>
      </div>

      <button class="auth" @click="showingPreview = true">Förhandsgranska</button>
    </div>
    <div v-else style="margin-top: 30px">
      <p class="auth text--warning" style="display: block; text-align: center">Ladda inte om sidan eller använd bakåtknappen i webläsaren för att gå bak!</p>
      <button class="auth" style="margin-right: 50px" @click="showingPreview = false">Fortsätt redigera</button>
      <button class="auth" style="position: relative; margin-bottom: 20px; background-color: #269400" @click="publish">
        <div v-if="publishLoading" class="auth-loader" style="position: absolute; left: 15px; top: 12px"></div>
        Publicera
      </button>
      <div style="display: flex; justify-content: center">
        <p class="auth text-hoverable" :style="{color: !previewEnglish ? '#269400' : 'black', fontWeight: !previewEnglish ? 'bold' : 'normal'}" style="margin-right: 10px" @click="previewEnglish = false">Svenska</p>
        <p class="auth text-hoverable" :style="{color: previewEnglish ? '#269400' : 'black', fontWeight: previewEnglish ? 'bold' : 'inherit'}" @click="previewEnglish = true">Engelska</p>
      </div>

      <!-- <button class="auth" style="background-color: #269400" @click="publish">Publicera</button> -->
      <news-article :title="[title[+previewEnglish], title[+previewEnglish]]" :content="[content[+previewEnglish], content[+previewEnglish]]" :date="date" :images="images" @exitPreview="showingPreview = false"/>
    </div>
  </div>
</template>

<script>
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
// import '../../style/news-post.css'
// import 'quill/dist/quill.bubble.css'

import { quillEditor } from 'vue-quill-editor'
import NewsArticle from '../../components/news/NewsArticle.vue'
import BackIcon from '../../assets/icons/back.svg'
import moment from 'moment'


export default {
  data() {
    return {
      title: ['', ''],
      content: ['', ''],
      images: [],
      english: false,
      previewEnglish: false,
      date: moment().locale('sv').format('MMM DD, YYYY'),
      imageLoading: false,
      publishLoading: false,
      showingPreview: false,
      editorOptions: {
        theme: 'snow',
        placeholder: 'Skriv här',
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline'], 
            [{ 'header': [1, 2, 3, false] }],
            [{ 'align': [] }]
          ]
        }
      }
    }
  },
  props: {
    editModel: {},
  },
  metaInfo() {
    return {
      title: 'Admin - Skriv'
    }
  },
  mounted() {
    if(this.editModel) {
      this.title = this.editModel.title
      this.content = this.editModel.content
      this.images = this.editModel.images
    }
  },
  methods: {
    async updateImages(event) {
      console.log(event.target.files[0].name, event.timeStamp)
      this.imageLoading = true
      const url = await this.$store.dispatch('uploadFile', { folder: 'news-images', file: event.target.files[0], time: event.timeStamp })
      this.imageLoading = false
      this.images.push(url)
    },
    async removeImage(url) {
      console.log('Removing image: ', url)
      this.$store.dispatch('removeFile', { url: url })
      this.images.splice(this.images.indexOf(url), 1)
    },
    async publish() {
      console.log('Publishing news article...')
      const timeStamp = this.editModel ? this.editModel.time : moment().format().toString()

      this.publishLoading = true
      await this.$store.dispatch('publishNews', { title: this.title, content: this.content, images: this.images, slug: this.slug(), timeStamp })
      this.publishLoading = false
      this.$router.go(-1)
    },
    test() {
      console.log(this.content[0])
    }, 
    slug() {
      if(this.editModel) return this.editModel.slug

      var slug = (this.title[0] ? this.title[0] : this.title[1]).replace(/[åäÅÄ]/g, 'a')
                            .replace(/[öÖ]/g, 'o')
                            .replace(/[^a-zA-Z0-9 ]/g, "")
                            .trim()
                            .replace(/\s/g, "-")
                            .toLowerCase()

      //Test slug for collisions
      var i = 2
      while(this.slugCollides(slug)) {
        slug = `${slug}-${i}`
        i++
      }

      return slug
    },
    slugCollides(slug) {
      for(const data of Object.entries(this.$store.state.news)) {
        if(slug === data[1].slug) return true
      }
      return false
    }
  },
  components: {
    quillEditor,
    NewsArticle,
    BackIcon
  }
}
</script>

<style scoped>
.admin-write {
  position: relative;
  max-width: 1000px;
  margin: 0 auto;
  background-color: white;
  padding: 20px;
  margin-top: 30px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.075);
}

.admin-write >>> .ql-formats > button {
  width: auto;
  padding: 0;
  margin: 0 10px;
  min-width: auto;
  border-radius: 0;
  box-shadow: none;
}

.admin-write >>> p {
  font-size: 20px;
}

.admin-write >>> h1 {
  font-size: max(2.3vw, 25px);
  letter-spacing: normal;
  text-transform: none;
}
.admin-write >>> h2 {
  font-size: max(2vw, 26px);
  text-transform: none;
  font-weight: normal;
  letter-spacing: 0.05em;
}
.admin-write >>> h3 {
  font-size: 24px;
  letter-spacing: 0.05em;
  text-transform: none;
}

.admin-write >>> .ql-editor {
  font-family: "Yantramanav", sans-serif !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}

.admin-write-image-preview-list {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  row-gap: 15px;
  column-gap: 15px;
}
.admin-write-image-preview {
  min-width: none;
  width: 150px;
  height: 150px;
  object-fit: contain;
}

</style>